.dialog_content {
    padding: 10px 20px !important; }

.dialog_content .dialog_input {
    width: 330px; }

.dialog_document_properties {
    display: flex;
    justify-content: end; }

.dialog_document_properties .dialog_input {
    width: 70px; }

.dialog_actions {
    display: flex;
    justify-content: end;
    padding: 10px 0; }

.dialog_actions .common_select-style input {
    width: 500px; }

.dialog_textfield {
    margin: 0;
    padding: 0; }

.dialog_content .common_select_container p {
    margin-right: 0; }

.dialog_content .error-message {
    display: flex;
    justify-content: end;
    padding-bottom: 15px;

    label {
        width: 330px;
        font-size: 15px; } }

h2 {
    display: flex;
    justify-content: space-between;
    align-items: center; }

h2 svg {
    cursor: pointer; }

.downloading_content {
    padding: 0px 50px; }

.flex_content {
    display: flex;
    justify-content: center; }

.downloading_content .downloading_title {
    line-height: 0;
    font-size: 20px;
    color: #525252; }

.downloading_content .labels {
    padding: 15px 0; }

.downloading_content .downloaded {
    padding: 30px;
    color: gray; }

.circle-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    padding: 15px;
    border-radius: 50%;
    background: green;
    color: white; }

.circle-icon svg {
    font-size: 40px; }

.download_result {
    padding: 10px 0; }

.download_result .flex_content {
    padding: 5px 0; }

.download_result b {
    font-size: 20px; }

.download_result .text {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 15px; }

.result_label {
    color: gray;
    font-size: 13px; }

.dialog_content .dialog_actions .MuiButton-root {
    margin: 0 3px; }
