.documents_dialog .dialog_content {
    height: 100%;
    background: whitesmoke; }

.documents_dialog .table_flex {
    padding: 5px 0; }

.documents_dialog .documents_table {
    margin: 10px 7px;
    width: 500px; }

.documents_dialog .submit {
    background: #37a537; }

.documents_dialog .submit:hover {
    background: #4bd54b; }

.documents_dialog .remove {
    background: #f56029; }

.documents_dialog .remove:hover {
    background: #ff956e; }

.documents_dialog .action_buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Clarika Office Geometric';
    padding-left: 10px; }

.documents_dialog .dialog_actions {
    display: flex;
    justify-content: end; }

.documents_dialog .documents_block {
    margin: 8px;
    padding: 8px 4px;
    background: white;
    border: 1px solid #e5e5e5;
    border-radius: 5px; }

.documents_dialog .MuiDialogTitle-root div {
    display: flex !important;
    align-items: center !important; }

.documents_dialog .submit div {
    display: flex;
    align-items: center;
    color: whitesmoke; }

.documents_dialog .submit .MuiCircularProgress-root {
    color: #9eff9c;
    margin-left: 8px; }

.documents_dialog .remove div {
    display: flex;
    align-items: center;
    color: whitesmoke; }

.documents_dialog .remove .MuiCircularProgress-root {
    color: #ffb39c;
    margin-left: 8px; }
