.file-container {
  margin-top: 25px; }

.pagination-container {
  display: flex;
  align-items: center; }
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #EAEAEA;
  border-bottom: none;
  min-height: 40px;
  padding: 0 10px;
  button {
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 50%;
    min-width: 20px;
    height: 20px;
    width: 20px;
    &:hover {
      background: #EAEAEA; } }
  button:last-child {
    margin-bottom: 0; }
  p {
    margin: 0;
    display: flex;
    align-items: baseline; }
  .page-input {
    margin: 0 5px;
    width: 33px;
    text-align: center; }
  hr {
    margin: 0 5px; }
  .show_all-checkbox-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center; }
  .downloadButton {
    width: 75px;
    height: 25px;
    background: orange;
    color: whitesmoke;
    border-radius: 5px; } }

.file {
  border: 1px solid #EAEAEA;
  border-radius: 2px;
  margin-bottom: 20px; }
.file_size {
  font-size: 400px;
  color: #EAEAEA; }

.pdf_viewer-component {
  overflow: auto;
  height: 94vh; }
