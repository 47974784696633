.validate_select-status-style {
  p {
    margin-right: 87px; } }
.validate_select-attribute-flex {
  margin-right: 30px; }
.validate_select-content-attribute {
  display: flex;
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 15px;
  justify-content: space-between; }
.table {
  margin-bottom: 30px; }
.table_flex {
  display: flex;
  justify-content: space-between; }
.buttons_flex {
  display: flex;
  justify-content: flex-end; }

.modal {
  width: 80%;
  background: #FFFFFF;
  margin: 5% auto 0;
  padding: 20px 20px 40px;
  border-radius: 10px;
  position: relative;
  p {
    font-size: 14px;
    line-height: 100%;
    color: #646469;
    width: 420px;
    margin-bottom: 44px; } }
.modal_download {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 110px;
  p {
    width: 360px!important; } }
.modal_button {
  position: absolute!important;
  right: 27px!important;
  margin-top: -20px!important; }

.externalDocumentsList {
  .data-grid {
    height: auto; } }

.externalDocumentsFooter {
  display: flex;
  padding: 10px 0;
  justify-content: end;

  .MuiButton-root {
    margin-left: 7px; } }
