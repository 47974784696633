.fileName-label {
    display: flex;
    align-items: center;
    cursor: pointer; }

.fileName-label .fileName_container {
    display: flex;
    align-items: center;
    padding-left: 90px;
    text-decoration: underline;
    color: orange; }

.fileName-label .name-label {
    display: flex;
    align-items: center; }

.fileName-label svg {
    color: orange; }

.fileName-label .filename_div__trippleDot {
    text-decoration: underline;
    color: orange; }
