.entityShow_buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px; }

.entityShow_input_container {
  width: 50%; }
.entityShow_input {
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-bottom: 30px; }

.entityShow_input__label {
  width: 150px; }
.entityShow_input__valueEditor {
  input {
    width: 18vw; } }
@media only screen and (max-width: 700px) {
  .entityShow_input_container {
    margin-top: 20px;
    width: 100%; }
  .entityShow_input {
    margin-right: 10px; } }

@media only screen and (max-width: 1390px) {
  .entityShow_input_container {
    width: 50%; } }
