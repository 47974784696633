#attribute_editor_input {
    input {
        font-size: 14px;
        width: 330px; } }
.deleteButton {
    min-width: 35px;
    width: 10%; }

.attributeEditor_valueField_containter {
    display: flex;
    justify-content: start;
    width: 60%; }

.attributeEditor_attributeName_containter {
    width: 35%; }
