.add-document-dialog-button {
    padding: 1px 10px; }

.requirement_paragraphs_title {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    font-size: 14px;

    label {
        font-weight: 500; } }
