.recognition_flex-header {
  p {
    color: #646469; } }
.scan_flex {
  display: flex;
  flex-direction: column;
  align-items: center; }

.scan_document {
  max-height: 500px;
  overflow: auto;
  max-width: 550px;
  padding: 24px 50px; }
.scan_flex-button {
  display: flex;
  align-items: center;
  span {
    padding: 0px 16px; } }
.recognition_container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  p {
    color: #646469;
    span {
      color: #7B7F86; } } }
.recognition_attribute {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }
.attribute_container {
  border: 1px solid #EAEAEA;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 34px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  button {
    margin-right: 0px!important; } }
.attribute_input {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  p {
    margin-right: 63px; } }
.attribute_textfield {
  width: 240px!important; }
.attribute_main {
  margin-bottom: 20px; }
.recognition_button {
  display: flex;
  align-items: center; }
