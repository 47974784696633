.compare_document-container {
  max-width: 1000px;
  width: 1000px;
  .showValidation-link {
    cursor: pointer; }
  .compareDocument-title {
    display: flex;
    height: 60px;
    align-items: center;
    padding: 15px 0;
    .documentNumber {
      margin-right: 20px; }
    a {
      margin-right: 32px; } } }

.compare_document-container.main {
    margin-right: 60px; }

.document_attributes {
  height: 215px;
  max-width: 475px;
  width: 100%;
  left: 120px;
  top: 217px;
  border: 1px solid #EAEAEA;
  border-radius: 2px; }

.document_attributes_table {
  height: 215px; }

.file-container-select {
  display: flex;
  align-items: center;
  margin-top: 25px;
  p {
    margin-right: 25px; } }
.file-container-select_content {
  width: 378px;
  margin-right: 25px; }
