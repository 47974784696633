.MuiButton-outlined {}
.document_compare_button {
  height: 24px;
  width: 105px;
  border-radius: 3px;
  font-size: 13px;
  color: #FFA839;
  .MuiSvgIcon-root {
    font-size: 14px;
    margin-right: 5px; } }
