.find_duplicates-header {
  display: flex;
  justify-content: space-between; }

.duplicates_button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px; }
.tableCell-style {
  border: 1px solid rgba(224, 224, 224, 1);
  a {
    text-decoration: none; } }
.tableRow-style {
  border: 1px solid rgba(224, 224, 224, 1); }
.findDuplicates_link-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 18px;
  button {
    border-radius: 3px;
    font-size: 12px;
    height: 24px; } }
