.unbind_document-dialog {
  display: flex;
  align-items: center;
  max-height: 262px;
  max-width: 570px;
  width: 100%;
  height: 100%;
  .unbind_document-dialog-content {
    padding: 50px 105px;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .unbind_document-dialog-header {
    font-size: 25px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    padding: 0px;
    padding-bottom: 15px; }
  .MuiDialogContent-root {
    padding: 0;
    padding-bottom: 40px; }
  .unbind_document-dialog-content-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center; }
  .MuiDialogActions-root {
    padding: 0;
    button {
      margin: 0; }
    button:first-child {
      margin-right: 30px; }
    button:last-child {
      background: #BCBCBC; } } }
.unbind_document-button {
  height: 24px;
  width: 105px;
  border-radius: 3px;
  color: #BCBCBC;
  border: 1px solid #BCBCBC;
  &:hover {
    border: 1px solid #5a5a5a;
    color: #5a5a5a; } }

