body {
  margin: 0px;
  font-family: Clarika Office Geometric; }
.header {
  padding: 23px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
.header_flex {
  display: flex;
  align-items: center; }
.header_img-profile {
  border-radius: 24px; }
.sidebar {
  width: 240px;
  height: 100vh;
  position: fixed;
  ul {
    margin-top: 35px;
    list-style: none; }
  li {
    font-size: 14px;
    line-height: 100%;
    margin-bottom: 30px;
    a {
      text-decoration: none;
      color: #323237; } } }
.header_dehaze {
  margin-right: 20px; }
.drawer_listItem {
  padding-right: 18px !important;
  padding-left: 45px !important;
  width: 250px; }
.drawer_listItem__inner {
  padding-right: 18px!important;
  padding-left: 70px!important;
  &:before {
    content: '.';
    display: inline-block;
    position: absolute;
    bottom: 19px;
    left: 60px; } }
.header_avatar {
  margin-left: 10px; }
.header_avatar__menu {
  margin-top: 60px; }
.layout_menu {
  width: 140px!important;
  transition: all .5s;
  &:hover {
    background: rgba(255, 168, 57, 0.28)!important; } }

.action-buttons {
  margin-bottom: 20px;
  button {
    padding: 8px 24px;
    margin: 0 8px;
    border-radius: 10px; } }

.show_action-buttons {
  div:first-child button:first-child {
    margin-left: 0; }
  div:last-child button:last-child {
    margin-right: 0; } }

.list_action-buttons {
  button:last-child {
    margin-right: 0; } }

.container {
  margin-left: 120px;
  margin-right: 120px;
  margin-top: 20px; }

@media only screen and (max-width: 1360px) {
  .container {
    margin-left: 20px;
    margin-right: 20px; } }
