.requirement .MuiFormControl-root {
    padding: 0; }

.requirement .common_select_container {
    width: 450px; }

.requirement .requirements_select-style {
    width: 255px;
    padding: 5px 0;
    font-size: 14px;
    color: #646469; }

.requirement .common_select_container .MuiTextField-root input {
    padding: 3px 0; }

requirements_paragraphLabel {
    color: orange; }

reqiurements_downloadButton {
    color: orange;
    cursor: pointer;
    padding-left: 48px; }

.requirement .red-label {
    padding: 0 5px;
    font-size: 16px;
    color: red;
    text-decoration: none; }

.requirement #autocomplete input {
    margin-left: 0; }

.requirement #autocomplete .MuiAutocomplete-endAdornment {
    padding: 0 6px; }

.requirement .common_flex-component {
    padding-bottom: 30px; }

.requirement .common_select_container .file-name {
    width: inherit;
    text-align: left; }

.requirement .common_select_container .file-name a {
    text-align: left;
    padding-left: 90px;
    text-decoration: underline;
    cursor: pointer;
    color: orange; }

.requirement .common_select_container .file-name span {
    margin-left: 5px;
    font-size: 14px;
    color: orange; }

.requirement .common_select_container .fileNameLabel {
    display: flex;
    align-items: center; }

.requirement .uploadButton {
    svg {
        color: white; } }

form-block {
    display: block; }

.table_flex {
    display: flex;
    justify-content: start;
    align-items: start;
    padding: 0 2px; }

.requirement_properties {
    padding-left: 90px; }

.requirement_properties label {
    margin: 0; }

.requirement p {
    display: flex;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Clarika Office Geometric'; }

.requirement .MuiButton-root .MuiButton-endIcon {
    color: whitesmoke; }

h4 {
    font-family: 'Clarika Office Geometric'; }

.content-block {
    display: block;
    margin-left: auto; }

.content-block div {
    margin: 8px 0;
    padding-right: 3px;
    text-align: right; }

.content-block a {
    font-family: 'Clarika Office Geometric';
    color: #6363ff; }

.downloaded .info-label {
    text-align: right;
    font-family: 'Clarika Office Geometric';
    font-size: 16px;
    color: orange;
    text-decoration: underline; }

.items-search .MuiFormControl-root {
    padding: 3px 0; }

.items-search .MuiOutlinedInput-root {
    height: 40px;
    padding: 0; }

.requirement common_select_container .MuiAutocomplete-root {
    padding: 0; }

.requirement .common_select_container .MuiAutocomplete-root input {
    padding: 0 12px; }

.requirement .requirement_properties p {
    width: 155px; }

.requirement .requirement_properties .info {
    display: flex;
    justify-content: start; }

.requirement .underline-block {
    display: flex;
    .underline-label {
        padding-right: 15px; } }

.items-search .MuiCircularProgress-root {
    color: gray; }

.items-search .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    padding: 0 7px;
    right: 0; }

.MuiAutocomplete-root .MuiOutlinedInput-root {
    font-size: 14px; }

.send-to-archive_button {
    background: #8787ff; }

.send-to-archive_button:hover {
    background: lightblue; }
