.tool-bar {
    display: flex;
    align-item: center;
    justify-content: end;
    padding-bottom: 10px; }

.dialog-body {
    width: 400px;
    padding: 10px 25px; }

.dialog-body p {
    display: flex;
    font-family: 'Clarika Office Geometric'; }

.dialog-body .red-label {
    padding: 0 8px;
    text-decoration: none;
    color: red; }

.dialog-body .MuiFormControl-root {
    min-width: 200px;
    padding: 8px; }

.dialog-body .MuiButton-root .MuiButton-endIcon {
    color: whitesmoke; }

.dialog-footer {
    display: flex;
    justify-content: center;
    padding: 10px; }
