.upload_flex_container {
  display: flex;
  column-gap: 8vw; }

.select-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin-right: 0px;
    display: flex;
    align-items: center; } }
.upload__attribute {
  margin-left: 53px;
  p {
    font-size: 14px;
    color: #323237;
    display: flex;
    align-items: center; } }
.table {
  margin-bottom: 30px; }
.table_flex {
  display: flex; }
.upload {
  border: 1px solid #b6b6b6;
  padding: 15px;
  margin-bottom: 30px; }
.upload_box {
  display: flex;
  align-items: center; }
.modal {
  width: 80%;
  background: #FFFFFF;
  margin: 5% auto 0;
  padding: 20px 20px 40px;
  border-radius: 10px;
  position: relative;
  p {
    font-size: 14px;
    line-height: 100%;
    color: #646469;
    width: 420px;
    margin-bottom: 44px; } }
.modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center; }
.modal_download {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 110px;
  p {
    width: 360px!important; } }
.modal_button {
  position: absolute!important;
  right: 27px!important;
  margin-top: -20px!important; }
.counterparty_label {
  display: flex;
  align-items: center;
  label {
    display: flex;
    align-items: baseline;
    p {
      margin-left: 10px;
      max-width: 384px;
      word-break: break-word; } } }
.attribute_flex {
  width: 700px;
  max-height: 300px;
  overflow-y: auto;
  padding-left: 40px; }
.attribute {
  display: flex;
  align-items: center;
  justify-content: space-between; }
.atibute_p {
  margin-right: 40px; }
.atibute_p-required {
  margin-right: 40px;
  font-weight: 700; }
.box {
  display: flex;
  justify-content: flex-end;
  max-height: 100px;
  overflow: auto; }
.box_flex {
    background: white;
    width: 103px;
    border: 1px solid #cacaca;
    margin-top: 7px;
    margin-right: 9px;
    p {
      margin: 5px!important; }
    li {
      justify-content: flex-end;
      padding: 0!important; } }
#filename {
  margin-right: 0px;
  span {
    font-size: 14px;
    text-decoration-line: none;
    color: #272727; } }

.filename_div__trippleDot {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap; }
