.externalDocumentScrollView {
    fieldset {
        padding: 10px 15px;
        border: 1px solid lightgray;
        overflow-x: scroll; }

    .file-list {
        display: flex; }

    .flex-item {
        display: flex;
        justify-content: center; }

    .document-item {
        width: 100px;
        height: 100px;
        margin: 3px;
        padding: 5px 7px;
        border: 1px solid whitesmoke;

        .pdf-icon {
            font-size: 50px;
            color: gray; }

        .document-name {
            font-size: 12px;
            text-align: center;
            overflow-wrap: anywhere; } }

    .document-item:hover {
        cursor: pointer;
        background: whitesmoke; }

    .file-list .active {
        background: #ffe4b2; } }
