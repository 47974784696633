.validate_button {
  display: flex;
  align-items: center;
  justify-content: space-between; }
.validate_select-type {
  p {
    margin-right: 106px!important; } }
.validate_select {
  display: flex;
  align-items: flex-start;
  column-gap: 8vw;
  margin-bottom: 8px; }

.validate_select-status {
  p {
    margin-right: 80px!important; } }
.validate_select-download {
  p {
    margin-right: 83px!important; } }
.validate_select-text {
  p {
    margin-right: 26px!important; } }
.validate_select-date {
  p {
    margin-right: 45px!important; } }
.table_validate {
  height: 250px!important;
  overflow-y: scroll;
  input {
    width: 200px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.11);
    outline: none;
    padding: 10px; } }
.validate_attribute-content {
  h3 {
    font-weight: 600;
    font-size: 14px;
    color: #323237; }
  button {
    margin: 0!important; } }
.validate_select-attribute {
  p {
    margin-right: 30px;
    align-items: center;
    display: flex; } }

.validate_attribute-button {
  margin-top: 45px; }
.validate_attribute-content-text {
  margin-left: -41px; }
.validate_select-content {
  margin-right: 30px; }
.fileName_container {
  cursor: pointer;
  width: 15vw; }
.document_info__span {
  span {
    text-decoration-line: underline;
    color: #FFA839;
    font-size: 14px;
    font-weight: normal;
    display: flex;
    align-items: center; } }

.compareDialog .MuiDialogContent-root {
  overflow-x: hidden; }
