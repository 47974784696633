.document_stack {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  .data-grid {
    height: auto;
    max-height: 30vh; } }
.document_stack-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px; }
