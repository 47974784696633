.data-grid {
  height: 58vh;
  max-height: 58vh; }
.data-grid-with-filters {
  height: 47vh; }
.MuiDataGrid-root {
  font-style: normal;
  letter-spacing: 0em;
  font-size: 14px;
  border: 0px; }
.MuiDataGrid-main {
  border-left: 1px solid #DEDEDE;
  .multiple-enum-cell {
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none; }
  .multiple-enum-cell::-webkit-scrollbar {
    display: none; } }
.MuiDataGrid-columnHeaders {
  background: #F7F7F8;
  .MuiDataGrid-pinnedColumnHeaders {
    background: #F7F7F8;
    border-bottom: 1px solid #DEDEDE; } }
.MuiDataGrid-cell {
  border-right: 1px solid #DEDEDE; }
.MuiDataGrid-cellContent {
  font-weight: 400;
  color: #323237; }
.css-9uxvuo-MuiTableCell-root {
  font-weight: 300; }
.MuiDataGrid-columnHeaderTitle {
  font-weight: 700;
  color: #646469; }
.actions-cell {
  button {
    margin: 0px 4px; }
  button:first-child {
    margin-left: 0px; }
  button:last-child {
    margin-right: 0px; }
  .MuiDataGrid-actionsCell {
    margin-top: 2px; } }
