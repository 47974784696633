.paragraphs_table {
    padding-top: 25px; }

.paragraphs_table .table_button {
    padding: 2px 10px;
    border-radius: 5px; }

.paragraphs_table .table_button:hover {
    background: orange; }

.paragraphs_actions {
    display: flex; }

.paragraphs_actions .MuiButton-root {
    margin: 0 5px; }

.paragraphs_flex-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 0; }

.empty-value-label {
    color: red; }

.name-label {
    font-size: 18px;
    font-weight: 600;
    font-family: 'Clarika Office Geometric'; }
