.common_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    h3 {
        font-size: 15px;
        color: #646469; } }

.common_header__buttons {
    display: flex;
    align-items: center;
    p {
        padding-right: 61px; }
    img {
        margin-right: 10px; } }

.common_select_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
        margin-right: 43px;
        font-size: 14px;
        color: #323237; } }

.common_select-style:before {
    border: none!important; }
.common_select-style {
    width: 15vw!important;
    font-size: 14px!important;
    color: #646469!important; }

.common_select-type {}
p {
    margin-right: 32px; }

.common_select-text {
    font-size: 14px!important;
    color: #646469!important; }

.common_attribute {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
.common_attribute__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px; }
.common_attribute-content {
    h3 {
        font-weight: 600;
        font-size: 14px;
        color: #323237; }
    button {
        margin: 0!important; } }

.common_attribute__editor {
    border: 1px solid rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    font-size: 14px; }
