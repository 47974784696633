.search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 25px; }

.search_input:before {
  border-bottom: none!important;
  p {
    display: flex;
    align-items: center;
    img {
      margin-left: 11px; } } }

.search-flex {
  display: flex;
  align-items: center;
  p {
    margin-left: 230px;
    cursor: pointer;
    img {
      padding-left: 10px; } } }

.filter_flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
  > div {
    margin-right: 24px; } }

.search_input__height {
  padding: 10px 14px; }

.table_style {
  max-height: 50vh;
  overflow-y: auto; }

.select_filter {
  margin-top: 3px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  &:hover {
    border-bottom: 2px solid rgba(0, 0, 0, 0.87);
    margin-bottom: -1px; }
  label {
    position: relative;
    left: -15px;
    top: 2px; }
  .MuiOutlinedInput-root {
    padding-top: 0px; }
  fieldset {
    border: none; } }
