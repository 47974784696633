.resetPassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  img {
    margin-bottom: 20px;
    margin-left: 36px; } }
.resetPassword_card {
  -webkit-box-shadow: 4px 4px 19px 0px rgba(34, 60, 80, 0.2)!important;
  -moz-box-shadow: 4px 4px 19px 0px rgba(34, 60, 80, 0.2)!important;
  box-shadow: 4px 4px 19px 0px rgba(34, 60, 80, 0.2)!important;
  border-radius: 24px!important;
  padding: 20px!important;
  width: 275px;
  display: flex;
  flex-direction: column;
  align-items: center; }
.resetPassword_input {
  width: 240px;
  margin-top: 10px!important;
  margin-bottom: 11px!important; }
.resetPassword_button {
  border-radius: 17px!important;
  width: 250px; }
.resetPassword_error-field {
  color: #ff0000; }
.resetPassword_card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line; }
