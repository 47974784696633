.dialog_content {
    padding: 10px 20px; }

.dialog_content .MuiTextField-root input {
    padding: 3px 0; }

.paragraph_dialog .dialog_content .common_select_container {
    width: 370px; }

.dialog_content .common_select-style {
    width: 380px;
    padding: 4px 0; }

.dialog_content p {
    display: flex; }

.dialog_content .red-label {
    padding: 0 5px;
    text-decoration: none;
    color: red; }

.dialog_content .paragraphs_select-style {
    width: 235px;
    font-size: 14px;
    color: #646469; }

.dialog_content .MuiAutocomplete-root {
    padding: 5px 0; }

.dialog_actions {
    display: flex;
    justify-content: center;
    padding: 10px 0; }

.dialog_actions .common_select-style input {
    width: 500px; }

.paragraph_dialog .dialog_textfield {
    margin: 0;
    padding: 5px 0; }

.paragraph_dialog p {
    padding-right: 15px; }

.paragraph_dialog .common_select_container .common_select-style {
    width: 380px; }

.paragraph_dialog .dialog_button {
    margin: 0; }
