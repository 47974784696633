.modal_file {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 210px;
    text-align: center; }
.modal_file_container {
    min-height: 300px;
    display: flex;
    justify-content: center; }
.modal_file__svg {
    width: 120px !important;
    height: 120px !important; }
.modal_file__deleteButton {
    width: 100%;
    display: flex;
    justify-content: flex-end; }
