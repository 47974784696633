.signIn {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  img {
    margin-bottom: 20px;
    margin-left: 36px; } }
.signIn_card {
  -webkit-box-shadow: 4px 4px 19px 0px rgba(34, 60, 80, 0.2)!important;
  -moz-box-shadow: 4px 4px 19px 0px rgba(34, 60, 80, 0.2)!important;
  box-shadow: 4px 4px 19px 0px rgba(34, 60, 80, 0.2)!important;
  border-radius: 24px!important;
  padding: 20px!important;
  width: 275px; }
.signIn_input {
  width: 240px;
  margin-top: 10px!important;
  margin-bottom: 11px!important; }
.signIn_button {
  border-radius: 17px!important;
  width: 250px; }
.signIn_a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.51); }
.signIn_typography {
  cursor: pointer;
  margin-top: 20px!important;
  text-decoration: none;
  color: #FFA839; }

